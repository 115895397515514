import React, { useState, useEffect } from 'react'
import logo_home from '../assets/logo_home.png';
import logo from '../assets/logo.png';
import { Link, useLocation } from "react-router-dom";



export default function Menu() {

  const [show, setShow] = useState(false);
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);

  }, [location]);

  return (

    <header>
      <div className="lg:flex hidden items-center justify-between text-white-100 py-8">
        <nav className='lg:flex hidden items-center gap-x-28'>
          <a href="/" className="logo">
            <img src={logo} alt="Logo" />
          </a>
        </nav>

        <ul className="text-md flex justify-center gap-12 items-center font-poppins">
          <li><Link to="/about" className={"uppercase" + (url === "/about" ? " active" : "")}>About us</Link></li>
          <li><Link to="/services" className={"uppercase" + (url === "/services" ? " active" : "")}>Services</Link></li>
          <li><Link to="/contact" className={"uppercase" + (url === "/contact" ? " active" : "")}>contact</Link></li>
        </ul>

        <div className="flex items-center gap-x-6">
          <a href='https://calendly.com/kickstartlabs'>
            <button className='text-black-50 font-semibold px-5 py-3 bg-white-100 rounded-md border-2 border-white-100 hover:bg-black-50 hover:text-white-100 transition-all hover:duration-500'>
              Schedule Meeting
            </button>
          </a>

          <Link to='/contact'>
            <button className='hover:text-black-50 font-semibold px-5 py-3 hover:bg-white-100 rounded-md border-2 border-white-100 bg-black-50 text-white-100 transition-all hover:duration-500'>
              Contact us
            </button>
          </Link>
        </div>
      </div>

      <div className="lg:hidden flex items-center justify-between pt-5 font-nutino-sans">
        <a href="/" className="logo w-1/2">
          <img src={logo} alt="Logo" />
        </a>

        <button onClick={() => setShow(!show)} className="nav cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-white-100">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
          </svg>
        </button>
      </div>

      {show ? <div className="sidebar fixed top-0 bottom-0 p-2 w-3/4 overflow-y-auto font-poppins font-semibold bg-menu z-20 left-0 transition-all duration-500">

        <div className="p-2.5 mt-1">
          <a href="/" className="">
            <img src={logo_home} alt="Logo" className='w-1/2' />
          </a>
        </div>

        <ul className='mt-24 px-2 text-black-50'>

          <Link to='/about'>
            <li className='p-3 bg-seagreen-100 shadow-lg rounded-md mb-8'>
              <button onClick={() => setShow(!show)} className='text-xl'>
                About
              </button>
            </li>
          </Link>

          <Link to='/services'>
            <li className='p-3 bg-seagreen-100 shadow-lg rounded-md mb-8'>
              <button onClick={() => setShow(!show)} className='text-xl'>
                Services
              </button>
            </li>
          </Link>

          <Link to='/contact'>
            <li className='p-3 bg-seagreen-100 shadow-lg rounded-md'>
              <button onClick={() => setShow(!show)} className='text-xl'>
                Contact
              </button>
            </li>
          </Link>

        </ul>


      </div> : null
      }


    </header >
  )
}
