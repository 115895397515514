import './App.css';
import Contact from './components/Contact';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import { HashRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">

      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </HashRouter>

    </div>
  );
}

export default App;
