import React, { useEffect } from 'react'
import Menu from './Menu'
import bg_top from '..//assets/bg_top.svg'
import Particle from './Particles'
import Slider from './Slider'
import services_1 from '..//assets/services_1.png'
import services_2 from '..//assets/services_2.png'
import services_3 from '..//assets/services_3.png'
import services_4 from '..//assets/services_4.png'
import services_5 from '..//assets/services_5.png'
import services_arrow from '..//assets/services_arrow.png'
import offer_1 from '..//assets/offer_1.png'
import offer_2 from '..//assets/offer_2.png'
import offer_3 from '..//assets/offer_3.png'
import offer_4 from '..//assets/offer_4.png'
import logo_home from '..//assets/logo_home.png'
import discord_1 from '..//assets/discord_1.png'
import telegram_1 from '..//assets/telegram_1.png'
import twitter_1 from '..//assets/twitter_1.png'
import whatsapp_1 from '..//assets/whatsapp_1.png'
import mail_1 from '..//assets/mail_1.png'
import { Link } from "react-router-dom";
import Accordion from './Accordion';
import technology_1 from '../assets/technology_1.png';
import technology_2 from '../assets/technology_2.png';
import technology_3 from '../assets/technology_3.png';
import technology_4 from '../assets/technology_4.png';
import technology_5 from '../assets/technology_5.png';
import technology_6 from '../assets/technology_6.png';
import technology_7 from '../assets/technology_7.png';
import technology_8 from '../assets/technology_8.png';
import partner_1 from '../assets/partner_1.png';
import partner_2 from '../assets/partner_2.png';
import partner_3 from '../assets/partner_3.png';
import partner_4 from '../assets/partner_4.png';
import partner_5 from '../assets/partner_5.png';
import partner_6 from '../assets/partner_6.png';
import partner_7 from '../assets/partner_7.png';
import partner_8 from '../assets/partner_8.png';
import partner_9 from '../assets/partner_9.png';
import partner_10 from '../assets/partner_10.png';
import partner_11 from '../assets/partner_11.png';
import partner_12 from '../assets/partner_12.png';
import partner_13 from '../assets/partner_13.png';
import partner_14 from '../assets/partner_14.png';
import partner_15 from '../assets/partner_15.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp, { useCountUp } from 'react-countup';

const accordionData = [
    {
        numbers: '01',
        title: 'What services does Kickstart labs offer?',
        content: `Our pricing varies based on the specific services and needs of your business. We offer flexible pricing options to suit your budget and requirements. Contact us to learn more.`
    },
    {
        numbers: '02',
        title: 'Which IT solution is for my business?',
        content: `Our pricing varies based on the specific services and needs of your business. We offer flexible pricing options to suit your budget and requirements. Contact us to learn more.`
    },
    {
        numbers: '03',
        title: 'How much do the services cost?',
        content: `Our pricing varies based on the specific services and needs of your business. We offer flexible pricing options to suit your budget and requirements. Contact us to learn more.`
    },
    {
        numbers: '04',
        title: 'How do I get started with the IT solutions?',
        content: `Our pricing varies based on the specific services and needs of your business. We offer flexible pricing options to suit your budget and requirements. Contact us to learn more.`
    }
];

export default function Home() {

    useCountUp({
        ref: 'counter',
        enableScrollSpy: true,
        scrollSpyDelay: 1200,
        useEasing: true
    });

    useEffect(() => {
        AOS.init({
            duration: 600,
            once: false,
            easing: 'ease'
        });
    }, [])

    return (
        <div className="bg-black-50 text-white-100 font-poppins overflow-hidden">
            <div>
                <img src={bg_top} alt='bg' className='absolute top-0 right-0 w-full'></img>
            </div>

            {/* hero section */}

            <div className='lg:px-20 px-6 container mx-auto relative z-20'>

                <Menu />
                <Particle />

                {/* top section */}

                <div className='lg:py-20 py-6 font-grotesk'>

                    <div>
                        <div className='flex items-center lg:gap-x-8 gap-x-4'>
                            <h1 data-aos='slide-right' className='lg:text-9xl font-bold lg:flex hidden items-center gap-x-8'>
                                YOUR  <span className='flex items-center'><svg width="120" height="100" viewBox="0 0 189 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="5.00037" y="5.00049" width="179" height="136" rx="60" stroke="white" strokeWidth="10" />
                                </svg>NE</span>
                            </h1>
                            <h1 className='lg:hidden block text-4xl font-bold'>
                                YOUR ONE
                            </h1>
                            <p data-aos='fade-up'>
                                Create your future<br></br>
                                WEB3 Business with<br></br>
                                Kickstart Labs
                            </p>
                        </div>

                        <div className='lg:flex items-center gap-x-8 mt-3'>
                            <h2 data-aos='slide-up' className='lg:text-5xl text-2xl italic text-white-100'>
                                Stop <span className='lg:text-9xl text-4xl font-bold text-gradient not-italic'>SOLUTIONS</span>
                            </h2>

                            <div>
                                <a href='https://calendly.com/kickstartlabs'>
                                    <button data-aos='slide-left' className='group flex items-center gap-x-8 lg:px-8 px-4 lg:py-6 py-2 border border-white-100 rounded-xl text-lg font-semibold mt-8 mx-auto hover:bg-white-100 hover:text-black-50'>
                                        Schedule Meeting

                                        <div><svg className='group-hover:opacity-0' width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="white" />
                                        </svg>
                                            <svg className='opacity-0 group-hover:opacity-100 -mt-4' width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="#181F2B" />
                                            </svg>
                                        </div>
                                    </button>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div className='lg:ml-44'>

                        <div className='lg:py-16 py-6'>

                            <p data-aos='slide-up' className='lg:text-7xl text-4xl font-bold font-grotesk lg:py-8 py-4'>
                                We believe that <i className='font-thin'>technology</i><br></br>
                                can change the <span className='text-gradient'>WORLD.</span>
                            </p>

                        </div>

                        <div className='lg:flex items-center justify-between'>
                            <div data-aos='slide-right' className='lg:w-1/2 flex items-center gap-x-6'>
                                <div className='w-4 h-28 bg-seagreen-100'>

                                </div>
                                <p className='lg:text-lg'>
                                    That's why we're committed to delivering innovative  solutions to businesses of all sizes. Kickstart labs offer individuals to set up their cryptocurrency ideas in real life through custom smart contracts, auditing, development, and launch.
                                </p>
                            </div>

                            <div data-aos='slide-left' className=''>
                                <Link to="/services">
                                    <button className='group flex items-center gap-x-8 lg:px-8 px-4 lg:py-6 py-2 border border-white-100 rounded-xl text-lg font-semibold mt-8 mx-auto hover:bg-white-100 hover:text-black-50'>
                                        View Services

                                        <div><svg className='group-hover:opacity-0' width="90" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="white" />
                                        </svg>
                                            <svg className='opacity-0 group-hover:opacity-100 -mt-4' width="90" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="#181F2B" />
                                            </svg>
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='lg:px-20 px-6 container mx-auto lg:py-20 py-6'>

                {/* services section */}

                <section id='services'>

                    <div className='flex items-center justify-between'>
                        <p data-aos='fade-right' className='lg:text-7xl text-4xl font-bold font-grotesk lg:py-8 py-4'>
                            Services
                        </p>

                        <Link to="/services">
                            <button data-aos='fade-left' className='group lg:flex hidden items-center gap-x-8 lg:px-8 lg:py-4 border border-white-100 rounded-xl text-lg font-semibold mt-8 hover:bg-white-100 hover:text-black-50'>
                                View All

                                <div><svg className='group-hover:opacity-0' width="70" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="white" />
                                </svg>
                                    <svg className='opacity-0 group-hover:opacity-100 -mt-4' width="70" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="#181F2B" />
                                    </svg>
                                </div>
                            </button>
                        </Link>
                    </div>

                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 items-center justify-center'>

                        <div data-aos='flip-left' className='p-10 rounded-xl service-gradient transition-all hover:bg-seagreen-100 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='lg:h-60 h-44'>
                                <img src={services_1} alt='services' className='w-24'></img>
                            </div>

                            <div className='flex items-center justify-between'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>
                                    DAPPS
                                </p>
                                <img src={services_arrow} alt='services-arrow' className='w-16'></img>
                            </div>
                        </div>

                        <div data-aos='flip-right' className='p-8 rounded-xl service-gradient transition-all hover:bg-seagreen-100 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='lg:h-56 h-44'>
                                <img src={services_2} alt='services' className='w-24'></img>
                            </div>

                            <div className='flex items-center justify-between'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>
                                    Smart Chain<br></br>
                                    Contracts
                                </p>
                                <img src={services_arrow} alt='services-arrow' className='w-16'></img>
                            </div>
                        </div>

                    </div>

                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-6 items-center justify-center mt-6'>

                        <div data-aos='flip-right' className='p-8 rounded-xl service-gradient group  transition-all hover:bg-seagreen-100 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='lg:h-64 h-44 flex items-start justify-between'>
                                <img src={services_3} alt='services' className='w-24'></img>
                                <img src={services_arrow} alt='services-arrow' className='w-16'></img>
                            </div>

                            <div className=''>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>
                                    Marketplaces
                                </p>
                            </div>
                        </div>

                        <div data-aos='flip-left' className='p-8 rounded-xl service-gradient group  transition-all hover:bg-seagreen-100 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='lg:h-64 h-44 flex items-start justify-between'>
                                <img src={services_4} alt='services' className='w-24'></img>
                                <img src={services_arrow} alt='services-arrow' className='w-16'></img>
                            </div>

                            <div className=''>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>
                                    Audit
                                </p>
                            </div>
                        </div>

                        <div data-aos='flip-right' className='p-8 rounded-xl service-gradient group  transition-all hover:bg-seagreen-100 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='lg:h-64 h-44 flex items-start justify-between'>
                                <img src={services_5} alt='services' className='w-24'></img>
                                <img src={services_arrow} alt='services-arrow' className='w-16'></img>
                            </div>

                            <div className=''>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>
                                    DAO
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* why us section */}

                <section id='us' className='lg:py-20 py-6'>
                    <div className='lg:flex items-start justify-between gap-8 lg:space-y-0 space-y-8'>
                        <div data-aos='slide-up' className='lg:w-1/2'>
                            <p className='lg:text-6xl text-4xl font-bold font-grotesk'>
                                Why Us?
                            </p>
                        </div>

                        <div className='grid lg:grid-cols-2 grid-cols-1 lg:grid-rows-2 grid-rows-1 gap-12 items-center justify-items-end'>
                            <div data-aos='fade-right' className='space-y-5'>
                                <p className='lg:text-3xl text-2xl font-bold font-grotesk text-seagreen-100'>Pinksale Safu Badge</p>
                                <p className='font-poppins'>
                                    Kickstart labs offer individuals to set up their cryptocurrency ideas in real life through custom smart contracts, auditing, development, and launch.
                                </p>
                            </div>

                            <div data-aos='fade-left' className='space-y-5'>
                                <p className='lg:text-3xl text-2xl font-bold font-grotesk text-seagreen-100'>Utility</p>
                                <p className='font-poppins'>
                                    We offer utilities for crypto-related projects. Our experienced developers have built many top-class utilities and we have hundreds of satisfied clients.
                                </p>
                            </div>

                            <div data-aos='fade-left' className='space-y-5'>
                                <p className='lg:text-3xl text-2xl font-bold font-grotesk text-seagreen-100'>Audit Partners With
                                    Pinksale Finance</p>
                                <p className='font-poppins'>
                                    We offer a clean audit report with GitHub link and certification provided by our team. Our audited projects are rewarded with an audit badge by Pinksale Finance.
                                </p>
                            </div>

                            <div data-aos='fade-right' className='space-y-5'>
                                <p className='lg:text-3xl text-2xl font-bold font-grotesk text-seagreen-100'>Consulting</p>
                                <p className='font-poppins'>
                                    Kickstart labs offer consultancy services to guide you through your journey. We help you with your project design, roadmaps, and marketing ideas.
                                </p>
                            </div>
                        </div>
                    </div>

                </section>

                {/* What we offer section */}

                <section id='offer' className='lg:py-20 py-6'>
                    <p data-aos='slide-down' className='lg:text-6xl text-4xl font-bold font-grotesk text-center lg:pb-20 pb-6'>
                        What we offer
                    </p>

                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-16 items-center justify-center'>
                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={offer_1} alt='offer'></img>

                            <div data-aos='fade-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>expertise</p>
                                <p>
                                    Our team of experienced experts have the knowledge and expertise
                                    to deliver innovative IT solutions
                                    that meet your unique needs.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={offer_2} alt='offer'></img>

                            <div data-aos='fade-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>technology</p>
                                <p>
                                    We stay up to date with the latest trends and technologies in the IT industry, so you can get the most advanced solutions available.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={offer_3} alt='offer'></img>

                            <div data-aos='fade-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>solutions</p>
                                <p>
                                    We take a personalized approach
                                    to every project, working closely with you to understand your business and create solutions.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={offer_4} alt='offer'></img>

                            <div data-aos='fade-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>results</p>
                                <p>
                                    Our track record speaks for itself – we've helped businesses of all sizes and industries achieve their goals with our IT solutions.
                                </p>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

            <div className='bg-white-50 lg:py-28 py-8 lg:px-20 px-6 container mx-auto text-black-100'>
                <div className='text-center space-y-8'>
                    <p className='lg:text-7xl text-4xl font-bold font-grotesk'>
                        We believe that <i className='font-thin'>technology</i>
                    </p>

                    <p className='lg:text-7xl text-4xl font-bold font-grotesk'>
                        can <span className='text-outline fade-in-text'>change</span> the world.
                    </p>

                    <p className='lg:w-1/2 flex mx-auto font-medium'>
                        That's why we're committed to delivering innovative IT solutions to businesses of all sizes. Our team of experienced professionals is dedicated to helping you achieve your goals and thrive in a rapidly evolving digital landscape.
                    </p>

                    <div className='lg:flex items-center justify-center gap-x-5 lg:pt-10 pt-5 lg:space-y-0 space-y-6'>

                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex items-center lg:text-5xl text-3xl font-semibold'>
                                <CountUp end={500} enableScrollSpy />
                                <span>+</span>
                            </div>

                            <p className='mt-3 text-sm'>Successful Projects</p>
                        </div>

                        <div className='-mt-12 lg:block hidden'>
                            <svg width="336" height="1" viewBox="0 0 336 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="336" height="1" fill="#11112B" />
                            </svg>
                        </div>

                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex items-center lg:text-5xl text-3xl font-semibold'>
                                <CountUp end={98} enableScrollSpy />
                                <span>%</span>
                            </div>

                            <p className='mt-3 text-sm'>Satisfied Clients</p>
                        </div>

                        <div className='-mt-12  lg:block hidden'>
                            <svg width="336" height="1" viewBox="0 0 336 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="336" height="1" fill="#11112B" />
                            </svg>
                        </div>

                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex items-center lg:text-5xl text-3xl font-semibold'>
                                <CountUp end={35} enableScrollSpy />
                                <span>+</span>
                            </div>

                            <p className='mt-3 text-sm'>Handled Countries</p>
                        </div>

                    </div>
                </div>
            </div>

            {/*  Technologies We Use section */}

            <div className='lg:py-20 py-6 container mx-auto'>
                <p data-aos='slide-down' className='lg:text-6xl text-4xl font-bold font-grotesk text-center lg:py-8 py-4'>
                    Technologies We Use
                </p>

                <marquee scrollamount='13'>
                    <div className='flex items-center lg:gap-x-28 gap-x-16 lg:mt-20 mt-8'>
                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_1} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>ajax</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_2} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>github</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_3} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>figma</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_4} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>css 3</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_5} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>graphql</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_6} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>docker</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_7} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>html 5</p>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-8'>
                            <img src={technology_8} alt=''></img>
                            <p className='text-seagreen-100 font-semibold uppercase'>firebase</p>
                        </div>
                    </div>
                </marquee>

            </div>

            {/* Client Stories */}

            <div className='lg:pb-20 py-6 lg:px-20 px-6 container mx-auto'>

                <p data-aos='slide-right' className='lg:text-6xl text-4xl font-bold font-grotesk lg:py-8 py-4'>
                    Client Stories
                </p>

                <div className='lg:flex items-center justify-between gap-8 lg:mt-20 mt-6 lg:space-y-0 space-y-8'>

                    <div className='space-y-10'>
                        <div className='space-y-5'>
                            <p className='lg:text-4xl text-2xl font-bold font-grotesk text-seagreen-100'>
                                Amazing!
                            </p>

                            <p>
                                “Kickstart labs's cybersecurity solution gave us the peace of mind we needed to focus on our business. They took the time to understand our unique needs and created a solution that protected our sensitive data and ensured compliance with industry regulations.”
                            </p>
                        </div>

                        <div className='flex items-center justify-end gap-x-5'>
                            <div className='text-right'>
                                <p className='md:text-xl text-lg font-bold'>Tom Johnson</p>
                                <p className='text-white-50'>CIO of Mika Medika Healthcare</p>
                            </div>

                            <div className='w-12 h-12 bg-white-50 rounded-full'>

                            </div>
                        </div>
                    </div>

                    <div className='h-64 bg-white-50 w-0.5 mx-6 lg:block hidden'>

                    </div>

                    <div className='space-y-10'>
                        <div className='space-y-5'>
                            <p className='lg:text-4xl text-2xl font-bold font-grotesk text-seagreen-100'>
                                Best Service
                            </p>

                            <p>
                                “We were struggling to keep up with the demands of our growing business until we partnered with Kickstart labs. Their custom software development solution has helped us streamline our operations and improve efficiency, saving us time and money.”
                            </p>
                        </div>

                        <div className='flex items-center justify-end gap-x-5'>
                            <div className='text-right'>
                                <p className='md:text-xl text-lg font-bold'>Jane Doe</p>
                                <p className='text-white-50'>COO of DEF Manufacturing</p>
                            </div>

                            <div className='w-12 h-12 bg-white-50 rounded-full'>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {/*  Technologies We Use section */}

            <div className='lg:pt-20 py-6 container mx-auto'>
                <p data-aos='slide-down' className='lg:text-6xl text-4xl font-bold font-grotesk text-center lg:py-8 py-4'>
                    Our Partners
                </p>

                <marquee scrollamount='13'>
                    <div className='flex items-center gap-x-8 lg:mt-20 mt-8'>
                        <img src={partner_1} alt='partner' className='w-28'></img>
                        <img src={partner_2} alt='partner' className='w-32'></img>
                        <img src={partner_3} alt='partner' className='w-24'></img>
                        <img src={partner_4} alt='partner' className='w-32'></img>
                        <img src={partner_5} alt='partner' className='w-24'></img>
                        <img src={partner_6} alt='partner' className='w-44'></img>
                        <img src={partner_7} alt='partner' className='w-32'></img>
                        <img src={partner_8} alt='partner' className='w-44'></img>
                        <img src={partner_9} alt='partner' className='w-32'></img>
                        <img src={partner_10} alt='partner' className='w-44'></img>
                        <img src={partner_11} alt='partner' className='w-24'></img>
                        <img src={partner_12} alt='partner' className='w-32'></img>
                        <img src={partner_13} alt='partner' className='w-24'></img>
                        <img src={partner_14} alt='partner' className='w-36'></img>
                        <img src={partner_15} alt='partner' className='w-48'></img>
                    </div>
                </marquee>

            </div>

            <div className='lg:pt-20 py-6 lg:px-20 px-6 container mx-auto'>
                <div className='text-center space-y-6'>
                    <p data-aos='slide-down' className='lg:text-6xl text-4xl font-bold font-grotesk'>
                        Our Team
                    </p>
                    <p>The passionate people behind our every projects in Kickstart Labs.</p>
                </div>

                <div className='lg:mt-20 mt-8'>
                    <Slider />
                </div>
            </div>

            {/* faq section */}

            <div className='lg:py-20 py-6 lg:px-20 px-6 container mx-auto'>
                <p data-aos='slide-right' className='lg:text-6xl text-4xl font-bold font-grotesk lg:py-8 py-4'>
                    Frequently<br></br>
                    Asked Questions
                </p>

                <div className=''>
                    <div data-aos="zoom-in" data-aos-delay="350" className="accordion space-y-5 py-12">
                        {accordionData.map(({ numbers, title, content }) => (
                            <Accordion title={title} content={content} numbers={numbers} />
                        ))}
                    </div>
                </div>
            </div>

            <div className='lg:pb-24 py-6 lg:px-20 px-6 container mx-auto'>
                <div data-aos='slide-right' className='lg:flex items-center gap-x-8'>
                    <h1 className='text-9xl font-bold lg:flex hidden items-center gap-x-8 font-grotesk'>
                        READY<span className='flex items-center'> F<svg width="120" height="100" viewBox="0 0 189 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="5.00037" y="5.00049" width="179" height="136" rx="60" stroke="white" strokeWidth="10" />
                        </svg>R THE</span>
                    </h1>
                    <h1 className='text-4xl font-bold lg:hidden block font-grotesk lg:text-left text-center'>
                        READY FOR THE
                    </h1>
                </div>

                <div className='lg:flex items-center gap-x-8 mt-3'>
                    <h2 data-aos='slide-right' className='lg:text-9xl text-4xl italic text-seagreen-100 font-thin lg:text-left text-center'>
                        NEXT <span className='lg:text-9xl text-4xl font-bold text-gradient not-italic'>LEVEL?</span>
                    </h2>

                    <a href='https://t.me/rogerz10x'>
                        <button data-aos='slide-left' className='group flex items-center gap-x-8 lg:px-8 px-4 lg:py-6 py-2 border border-white-100 rounded-xl text-lg font-semibold mt-8 mx-auto transition-all hover:bg-white-100 hover:duration-500 hover:text-black-50'>
                            GET FREE CONSULTATION

                            <div><svg className='group-hover:opacity-0' width="90" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="white" />
                            </svg>
                                <svg className='opacity-0 group-hover:opacity-100 -mt-4' width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="#181F2B" />
                                </svg>
                            </div>
                        </button>
                    </a>
                </div>
            </div>

            {/* footer section */}

            <div className=''>
                <div className='bg-white-50 lg:py-28 py-8 lg:px-20 px-6 container mx-auto text-black-50 bg-footer'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-start justify-between gap-12'>

                        <div className='col-span-2'>
                            <a href='/'>
                                <img src={logo_home} alt='logo' className='lg:w-56 w-44'></img>
                            </a>

                            <div className='flex items-center flex-wrap gap-5 mt-8'>
                                <a href='/'><img src={discord_1} alt='Discord'  className='w-12'></img></a>
                                <a href='https://t.me/kickstartlabs'><img src={telegram_1} alt='Telegram'  className='w-12'></img></a>
                                <a href='https://twitter.com/kickstartlabsio'><img src={twitter_1} alt='Twitter'  className='w-12'></img></a>
                                <a href='https://wa.me/447300517422'><img src={whatsapp_1} alt='whatsapp' className='w-12'></img></a>
                                <a href='mailto:info@kickstartlabs.io'><img src={mail_1} alt='Email' className='w-12'></img></a>
                                <p className='text-lg'>Get in touch with us</p>
                            </div>

                            <div className='absolute ml-64 md:block hidden'>
                                <svg width="110" height="52" viewBox="0 0 130 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.231295 46.271L8.68235 54.1394L11.2711 42.8864L0.231295 46.271ZM8.76409 49.26C19.7839 51.9583 32.6103 55.5234 45.6319 58.0442C58.6595 60.5662 71.9891 62.0668 84.0841 60.6124C96.1967 59.1559 107.131 54.7266 115.256 45.3436C123.372 35.9728 128.575 21.7889 129.499 1.0441L127.501 0.955073C126.589 21.4274 121.467 35.1175 113.745 44.0343C106.033 52.939 95.6177 57.2111 83.8453 58.6267C72.0552 60.0445 58.9641 58.588 46.012 56.0807C33.0539 53.5722 20.3415 50.0358 9.23976 47.3173L8.76409 49.26Z" fill="#000" />
                                </svg>
                            </div>

                            <p className='md:mt-5 mt-3 md:text-3xl text-lg font-semibold'>+447300517422</p>
                        </div>

                        <div className='col-span-1'>
                            <h5 className='text-2xl font-semibold mb-5'>
                                Explore
                            </h5>
                            <ul className='space-y-4 text-white-50'>
                                <li><Link onClick={window.scrollTo(0, 0)} to='/about' className='text-white-200 font-semibold'>About Us</Link></li>
                                <li><Link onClick={window.scrollTo(0, 0)} to='/services' className='text-white-200 font-semibold'>Services</Link></li>
                                <li><Link onClick={window.scrollTo(0, 0)} to='/contact' className='text-white-200 font-semibold'>Contact</Link></li>
                            </ul>
                        </div>

                        <div className='col-span-1'>
                            <h5 className='text-2xl font-semibold mb-5'>
                                Contact Us
                            </h5>
                            <ul className='space-y-4 text-white-50'>
                                <li><a href='mailto:info@kickstartlabs.io' className='text-white-200 font-semibold'>Email</a></li>
                                <li><a href='https://wa.me/447300517422' className='text-white-200 font-semibold'>Phone</a></li>
                                <li><a href='/' className='text-white-200 font-semibold'>Address</a></li>
                                <li><a href='/' className='text-white-200 font-semibold'>Social Media</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className='lg:flex justify-between lg:mt-8 mt-6'>

                        <div>

                        </div>

                        <div className='lg:w-6/12'>
                            <h5 className='text-2xl font-bold mb-5 font-grotesk'>
                                Newsletter
                            </h5>

                            <form>
                                <div className='py-3 border-b-2 border-white-200 rounded-sm mt-5 lg:w-9/12 flex items-center justify-between'>
                                    <input placeholder='Enter your email address to join our mailing list ...' type='email' className='bg-white-50 focus:outline-none w-full placeholder-white-200'></input>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-8 h-8 cursor-pointer">
                                        <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </div >
    )
}
