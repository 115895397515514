import React, { useState } from 'react';

const Accordion = ({ numbers, title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item border-b-2 border-white-50 py-4 cursor-pointer lg:w-10/12 flex flex-col mx-auto">
            <div className="accordion-title flex justify-between items-start gap-4 group" onClick={() => setIsActive(!isActive)}>
                <div className='font-russo font-semibold lg:text-xl pb-4 text-white-100 group-hover:text-seagreen-100'>
                    {title}
                </div>

                <div className='font-russo font-bold lg:text-2xl text-white-50 group-hover:text-white-100'>
                    {numbers}
                </div>
            </div>
            {isActive && <div className="accordion-content font-inter text-white-100 mt-4 mb-3">{content}</div>}
        </div>
    );
};

export default Accordion
