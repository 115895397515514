import React from 'react'
import logo_footer from '..//assets/logo_footer.png'
import discord from '..//assets/discord.png'
import telegram from '..//assets/telegram.png'
import twitter from '..//assets/twitter.png'
import whatsapp from '..//assets/whatsapp.png'
import Email from '..//assets/Email.png'

import { Link } from "react-router-dom";




export default function Footer() {

  return (
    <footer className='text-white-100'>

      <div className='bg-solutions'>
        <div className='text-center lg:py-20 py-8 lg:px-20 px-6 container mx-auto'>
          <p data-aos='fade-down' className='lg:text-7xl text-4xl font-bold font-grotesk'>Need IT Solutions?</p>
          <p data-aos='fade-down' className='lg:text-7xl text-4xl font-bold font-grotesk'>Let’s <span className='italic font-normal'>start now.</span></p>

          <button data-aos='zoom-in' className='group flex items-center gap-x-8 lg:px-8 px-4 lg:py-6 py-2 border border-white-100 rounded-xl text-lg font-semibold mt-8 mx-auto hover:bg-white-100 hover:text-black-50'>
            GET FREE CONSULTATION

            <div><svg className='group-hover:opacity-0' width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="white" />
            </svg>
              <svg className='opacity-0 group-hover:opacity-100 -mt-4' width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100.705 8.70808C101.096 8.31756 101.096 7.68439 100.705 7.29387L94.3414 0.929909C93.9509 0.539384 93.3177 0.539384 92.9272 0.929909C92.5367 1.32043 92.5367 1.9536 92.9272 2.34412L98.5841 8.00098L92.9272 13.6578C92.5367 14.0484 92.5367 14.6815 92.9272 15.072C93.3177 15.4626 93.9509 15.4626 94.3414 15.072L100.705 8.70808ZM-0.00170898 9.00098H99.9983V7.00098H-0.00170898L-0.00170898 9.00098Z" fill="#181F2B" />
              </svg>
            </div>
          </button>
        </div>
      </div>

      <div className='lg:px-20 px-6 container mx-auto'>

        <div className='grid lg:grid-cols-7 md:grid-cols-3 grid-cols-2 items-start justify-between gap-12 lg:pt-20 pt-8 pb-8'>

          <div className='col-span-3'>
            <div>
              <img src={logo_footer} alt='logo' className='lg:w-56 w-44'></img>
            </div>

            <div className='flex flex-wrap items-start gap-4 mt-6'>
              <a href='/'><img src={discord} alt='Discord' className='w-8'></img></a>
              <a href='https://t.me/kickstartlabs'><img src={telegram} alt='Telegram' className='w-8'></img></a>
              <a href='https://twitter.com/kickstartlabsio'><img src={twitter} alt='Twitter' className='w-8'></img></a>
              <a href='https://wa.me/447300517422'><img src={whatsapp} alt='whatsapp' className='w-8'></img></a>
              <a href='mailto:info@kickstartlabs.io'><img src={Email} alt='Email' className='w-8'></img></a>
              <p>Get in touch with us</p>
            </div>

            <div className='absolute ml-64 md:block hidden'>
              <svg width="110" height="52" viewBox="0 0 130 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.231295 46.271L8.68235 54.1394L11.2711 42.8864L0.231295 46.271ZM8.76409 49.26C19.7839 51.9583 32.6103 55.5234 45.6319 58.0442C58.6595 60.5662 71.9891 62.0668 84.0841 60.6124C96.1967 59.1559 107.131 54.7266 115.256 45.3436C123.372 35.9728 128.575 21.7889 129.499 1.0441L127.501 0.955073C126.589 21.4274 121.467 35.1175 113.745 44.0343C106.033 52.939 95.6177 57.2111 83.8453 58.6267C72.0552 60.0445 58.9641 58.588 46.012 56.0807C33.0539 53.5722 20.3415 50.0358 9.23976 47.3173L8.76409 49.26Z" fill="white" />
              </svg>
            </div>

            <p className='md:mt-5 mt-3 md:text-3xl text-lg font-semibold'>+447300517422</p>
          </div>

          <div className='col-span-1'>
            <h5 className='text-2xl font-semibold mb-5'>
              Explore
            </h5>
            <ul className='space-y-4 text-white-50'>
              <li><Link onClick={window.scrollTo(0, 0)} to='/about' className='hover:text-white-100'>About Us</Link></li>
              <li><Link onClick={window.scrollTo(0, 0)} to='/services' className='hover:text-white-100'>Services</Link></li>
              <li><Link onClick={window.scrollTo(0, 0)} to='/contact' className='hover:text-white-100'>Contact</Link></li>
            </ul>
          </div>

          <div className='col-span-1'>
            <h5 className='text-2xl font-semibold mb-5'>
              Contact
            </h5>
            <ul className='space-y-4 text-white-50'>
              <li><a href='mailto:info@kickstartlabs.io' className='hover:text-white-100'>Email</a></li>
              <li><a href='https://wa.me/447300517422' className='hover:text-white-100'>Phone</a></li>
              <li><a href='/' className='hover:text-white-100'>Address</a></li>
              <li><a href='/' className='hover:text-white-100'>Social Media</a></li>
            </ul>
          </div>

          <div className='col-span-2'>
            <h5 className='text-2xl font-semibold mb-5'>
              Newsletter
            </h5>
            <p className='text-white-50'>
              Subscribe to our newsletter to stay informed about our latest products, services, and promotions.
              Feel free to unsubscribe anytime!
            </p>

            <form>
              <div className='p-3 border border-white-50 rounded-sm w-full bg-black-50 mt-8 flex items-center justify-between'>
                <input placeholder='Enter Your Email Address ...' type='email' className='bg-black-50 focus:outline-none w-full'></input>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-6 h-6">
                  <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>
              </div>

            </form>

          </div>


        </div>

        <div className='text-white-50'>
          <hr className='text-white-50'></hr>

          <div className='py-6 md:flex items-center justify-between md:space-y-0 space-y-3'>
            <p>Copyright © {new Date().getFullYear()} for Kickstart labs. All rights reserved.</p>

            <div className='flex items-center gap-2'>
              <a href='//' className='hover:text-white-100'>Terms & Condition</a>
              |
              <a href='//' className='hover:text-white-100'>Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}
