import React, { useEffect } from 'react'
import Menu from './Menu'
import Particle from './Particles'
import services_1 from '..//assets/services_1.png'
import services_2 from '..//assets/services_2.png'
import services_3 from '..//assets/services_3.png'
import services_4 from '..//assets/services_4.png'
import services_5 from '..//assets/services_5.png'
import services_7 from '..//assets/services_7.png'
import services_6 from '..//assets/services_6.png'
import services_8 from '..//assets/services_8.png'
import services_9 from '..//assets/services_9.png'
import services_10 from '..//assets/services_10.png'
import services_11 from '..//assets/services_11.png'
import services_12 from '..//assets/services_12.png'
import services_13 from '..//assets/services_13.png'
import services_14 from '..//assets/services_14.png'
import services_15 from '..//assets/services_15.png'
import services_arrow from '..//assets/services_arrow.png'
import work_1 from '..//assets/work_1.png'
import work_2 from '..//assets/work_2.png'
import work_3 from '..//assets/work_3.png'
import work_4 from '..//assets/work_4.png'
import Footer from './Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';



function Services() {

    useEffect(() => {
        AOS.init({
            duration: 600,
            once: false,
            easing: 'ease'
        });
    }, [])

    return (
        <div className='bg-black-50 text-white-100 font-poppins'>

            <div className='lg:px-20 px-6 container mx-auto'>
                <Menu />

                <div>
                    <h1 data-aos='slide-right' className='lg:text-7xl text-4xl font-bold font-grotesk py-8'>
                        Services
                    </h1>

                    <div className='py-6 grid lg:grid-cols-3 grid-cols-1 lg:grid-rows-5 grid-rows-1 items-start justify-center gap-8'>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_1} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>DAPP</p>
                                <p className='pt-6'>Kickstart labs offers development services for clients looking for new and existing dAPP concepts.</p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_2} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Smart Chain
                                    Contracts</p>
                                <p className='pt-6'>
                                    Our contract engineers are leaders in code, development, deployment and excel in an assortment of smart contract logic requirements.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_3} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Marketplaces</p>
                                <p className='pt-6'>
                                    NFTs are now transferable from one blockchain to another. Being an integrated part of the platform. Kickstart labs brings you multi-chain marketplace brings innovation in an interoperable environment.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_4} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>DAO</p>
                                <p className='pt-6'>
                                    A decentralized autonomous organization, sometimes called a decentralized autonomous corporation, is an organization constructed by rules encoded as a computer program that is often transparent, controlled by the organization's members and not influenced by a central government
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_5} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>AUDIT</p>
                                <p className='pt-6'>
                                    Here at kickstart labs generate clean audit reports for tokens based on all chains bsc, eth, sol, polygon and etc. Our reports are generated on the basis of manual and bot analysis. We also give proper certification to our projects on this basis.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_6} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>ICO</p>
                                <p className='pt-6'>
                                    Initial Coin Offerings" are another form of cryptocurrency that businesses use in order to raise capital. Through ICO trading platforms, investors receive unique cryptocurrency “tokens” in exchange for their monetary investment in the business.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_7} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>IDO</p>
                                <p className='pt-6'>
                                    An initial DEX offering, or IDO, is a new and exciting type of decentralized and permissionless crowdfunding platform, which is opening up a new way of fundraising in the crypto space. If a project is launching an IDO, it means the project is launching a coin or token via a decentralized liquidity exchange.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_8} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Farming</p>
                                <p className='pt-6'>
                                    Yield farming involves lending or staking cryptocurrency in exchange for interest and other rewards. Yield farmers measure their returns in terms of annual percentage yields (APY). While potentially profitable, yield farming is also incredibly risky.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_9} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>NFT Minting</p>
                                <p className='pt-6'>
                                    An NFT is a blockchain-based token that proves ownership of a digital item such as images, video files, and even physical assets. In simple terms, Minting an NFT refers to converting digital files into crypto collections or digital assets stored on the blockchain.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_10} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Auction Pool</p>
                                <p className='pt-6'>
                                    Type of ICO distribute rewards among participants according to blocks they found. In the real world, miners can join some pools to share the variance of these kind of rewards they can get timely.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_11} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Staking</p>
                                <p className='pt-6'>
                                    Proof-of-stake protocols are a class of consensus mechanisms for blockchains that work by selecting validators in proportion to their quantity of holdings in the associated cryptocurrency. This is done to avoid the computational cost of proof-of-work schemes. We offer both NFT Staking and Token Staking.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_12} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Bridging Between Chains</p>
                                <p className='pt-6'>
                                    Kickstart Labs offers communities new or established with a means to take their token currency cross chain.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_13} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Web Development</p>
                                <p className='pt-6'>
                                    Our team is made up of intelligent programmers who are capable of creating responsive websites in HTML, CSS, JS, and PHP. They are also proficient in WordPress Laravel and JS-related frameworks like vuejs and reactjs.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_14} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Graphic Design</p>
                                <p className='pt-6'>
                                    We offer full range of Graphical solutions from, Brand Identity, Print Media, Digital Media, Animation and character design, Web Design services along with most in demand these days, NFTs design services as well.
                                </p>
                            </div>

                        </div>

                        <div className='services-box rounded-2xl p-8 service-gradient lg:transition-all hover:bg-seagreen-100 lg:hover:duration-300 hover:shadow-md hover:shadow-seagreen-100'>
                            <div className='flex items-center justify-between'>
                                <img src={services_15} alt='services' className='w-24'></img>

                                <img src={services_arrow} alt='services'></img>
                            </div>

                            <div className='pt-12'>
                                <p className='lg:text-4xl text-2xl font-bold font-grotesk'>Video Animation</p>
                                <p className='pt-6'>
                                    At Kickstart labs we are truly committed on bringing our clients words to life. We want you to be seen, heard and understood. From brands Promotional videos, Intro videos, 3D animations, and product videos we have you covered.
                                </p>
                            </div>

                        </div>

                    </div>

                    {/* how it works */}

                    <div className='lg:py-20 py-6 relative z-20'>

                        <Particle />

                        <h2 className='lg:text-6xl text-4xl font-bold font-grotesk lg:pb-12 pb-6 text-center'>
                            How It Works
                        </h2>

                        <div className='flex flex-col items-center justify-center gap-y-8'>

                            <div className='flex flex-col items-center justify-center gap-y-8'>
                                <div data-aos='fade-down' className='flex flex-col items-center justify-center group'>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-1 h-24 bg-white-100 -mt-1 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                </div>

                                <div data-aos='zoom-in' className='service-gradient p-6 rounded-xl'>
                                    <img src={work_1} alt='work'></img>
                                </div>

                                <div data-aos='fade-up' className='flex flex-col items-center justify-center gap-y-8 text-center'>
                                    <p className='lg:text-2xl text-xl font-bold'>01</p>
                                    <p className='lg:text-3xl text-xl font-bold text-seagreen-100 font-grotesk'>Discovery</p>
                                    <p className='text-white-50 text-sm lg:leading-relaxed'>
                                        We'll conduct a thorough analysis of your<br></br> operations, market, and competition to<br></br>
                                        identify the best solutions for you.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col items-center justify-center gap-y-8'>
                                <div data-aos='fade-down' className='flex flex-col items-center justify-center group'>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-1 h-24 bg-white-100 -mt-1 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                </div>

                                <div data-aos='zoom-in' className='service-gradient p-6 rounded-xl'>
                                    <img src={work_2} alt='work'></img>
                                </div>

                                <div data-aos='fade-up' className='flex flex-col items-center justify-center gap-y-8 text-center'>
                                    <p className='lg:text-2xl text-xl font-bold'>02</p>
                                    <p className='lg:text-3xl text-xl font-bold text-seagreen-100 font-grotesk'>Planning</p>
                                    <p className='text-white-50 text-sm lg:leading-relaxed'>
                                        Once we have a clear understanding,<br></br>
                                        we develop a customized plan for<br></br> the services and solutions we provide.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col items-center justify-center gap-y-8'>
                                <div data-aos='fade-down' className='flex flex-col items-center justify-center group'>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-1 h-24 bg-white-100 -mt-1 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                </div>

                                <div data-aos='zoom-in' className='service-gradient p-6 rounded-xl'>
                                    <img src={work_3} alt='work'></img>
                                </div>

                                <div data-aos='fade-up' className='flex flex-col items-center justify-center gap-y-8 text-center'>
                                    <p className='lg:text-2xl text-xl font-bold'>03</p>
                                    <p className='lg:text-3xl text-xl font-bold text-seagreen-100 font-grotesk'>Development</p>
                                    <p className='text-white-50 text-sm lg:leading-relaxed'>
                                        We begin the design and development<br></br> process, creating custom solutions for<br></br> your specific business needs.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col items-center justify-center gap-y-8'>
                                <div data-aos='fade-down' className='flex flex-col items-center justify-center group'>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-1 h-24 bg-white-100 -mt-1 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                    <div className='w-5 h-5 rounded-full bg-white-100 group-hover:bg-seagreen-100 group-hover:shadow-md group-hover:shadow-seagreen-100'></div>
                                </div>

                                <div data-aos='zoom-in' className='service-gradient p-6 rounded-xl'>
                                    <img src={work_4} alt='work'></img>
                                </div>

                                <div data-aos='fade-up' className='flex flex-col items-center justify-center gap-y-8 text-center'>
                                    <p className='lg:text-2xl text-xl font-bold'>04</p>
                                    <p className='lg:text-3xl text-xl font-bold text-seagreen-100 font-grotesk'>Implementation</p>
                                    <p className='text-white-50 text-sm lg:leading-relaxed'>
                                        We implement our solution into your<br></br> business operations and integrate them<br></br> with other systems and platforms.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            <Footer />

        </div>
    )
}

export default Services
