import React, { useEffect } from 'react'
import Menu from './Menu'
import Footer from './Footer'
import Particle from './Particles'
import about_1 from '..//assets/about_1.png'
import about_2 from '..//assets/about_2.png'
import about_3 from '..//assets/about_3.png'
import about_4 from '..//assets/about_4.png'
import CountUp, { useCountUp } from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {

    useCountUp({
        ref: 'counter',
        enableScrollSpy: true,
        scrollSpyDelay: 1200,
        useEasing: true
    });

    useEffect(() => {
        AOS.init({
            duration: 600,
            once: false,
            easing: 'ease'
        });
    }, [])

    return (
        <div className='bg-black-50 text-white-100 font-poppins'>
            <div className='lg:px-20 px-6 container mx-auto relative z-20'>
                <Menu />
                <Particle />

                <div className=' lg:py-20 py-12'>
                    <p data-aos='slide-right' className='lg:text-7xl text-4xl font-bold font-grotesk'>
                        About Us
                    </p>

                    <div className='lg:flex items-end justify-between lg:pt-16 py-6 lg:sapace-y-0 space-y-6'>
                        <p data-aos='slide-right' className='lg:text-4xl text-2xl font-bold font-grotesk '>
                            We believe that technology<br></br>
                            can change the world.
                        </p>

                        <div data-aos='slide-left' className='lg:w-1/2 space-y-8 text-white-50'>
                            <p>
                                That's why we're committed to delivering innovative IT solutions to businesses of all sizes. Our team of experienced professionals is dedicated to helping you achieve your goals and thrive in a rapidly evolving digital landscape.
                            </p>
                            <p>
                                Our team is made up of entrepreneurs, developers, marketing professionals, designers and financial advisors that are located around the world. The team incorporates a massive amount of industry experience and talent that will make kickstart labs a successful global business.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className='bg-white-50 lg:py-20 py-8 lg:px-20 px-6 container mx-auto text-black-100'>

                <div className='lg:py-20 py-6 lg:space-y-16 space-y-6'>

                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-16 items-center justify-center'>
                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={about_1} alt='about'></img>

                            <div data-aos='slide-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>expertise</p>
                                <p>
                                    Our team of experienced experts have the knowledge and expertise
                                    to deliver innovative IT solutions
                                    that meet your unique needs.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={about_2} alt='about'></img>

                            <div data-aos='slide-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>technology</p>
                                <p>
                                    We stay up to date with the latest trends and technologies in the IT industry, so you can get the most advanced solutions available.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={about_3} alt='about'></img>

                            <div data-aos='slide-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>solutions</p>
                                <p>
                                    We take a personalized approach
                                    to every project, working closely with you to understand your business and create solutions.
                                </p>
                            </div>
                        </div>

                        <div className='space-y-12'>
                            <img data-aos='zoom-in' src={about_4} alt='about'></img>

                            <div data-aos='slide-up' className='space-y-3'>
                                <p className='lg:text-2xl text-xl font-bold'>results</p>
                                <p>
                                    Our track record speaks for itself – we've helped businesses of all sizes and industries achieve their goals with our IT solutions.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* counter section */}

                    <div className='bg-black-50 lg:py-16 py-6 lg:px-28 px-6 container mx-auto rounded-3xl'>
                        <div className='md:flex items-center justify-between lg:gap-x-12 gap-x-4 lg:pt-10 pt-6 md:space-y-0 space-y-8'>
                            <div className='flex flex-col items-center justify-center'>
                                <div className='flex items-center lg:text-7xl text-4xl font-semibold text-seagreen-100'>
                                    <CountUp end={500} enableScrollSpy />
                                    <span>+</span>
                                </div>

                                <p className='mt-3 lg:text-xl text-lg text-white-100 font-semibold'>Successful Projects</p>
                            </div>

                            <div className='w-2 h-2 bg-seagreen-100 rounded-full md:block hidden'>
                            </div>

                            <div className='flex flex-col items-center justify-center'>
                                <div className='flex items-center lg:text-7xl text-4xl font-semibold text-seagreen-100'>
                                    <CountUp end={98} enableScrollSpy />
                                    <span>%</span>
                                </div>

                                <p className='mt-3 lg:text-xl text-lg text-white-100 font-semibold'>Satisfied Clients</p>
                            </div>

                            <div className='w-2 h-2 bg-seagreen-100 rounded-full md:block hidden'>
                            </div>

                            <div className='flex flex-col items-center justify-center'>
                                <div className='flex items-center lg:text-7xl text-4xl font-semibold text-seagreen-100'>
                                    <CountUp end={35} enableScrollSpy />
                                    <span>+</span>
                                </div>

                                <p className='mt-3 lg:text-xl text-lg text-white-100 font-semibold'>Handled Countries</p>
                            </div>

                        </div>
                    </div>

                    {/* video section */}

                    <iframe data-aos='zoom-in' className='w-full rounded-3xl' width="" height="415" src="https://www.youtube.com/embed/ZJ4YxIqUIZw?si=NBkAd6ZRc_II3m2G" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default About
